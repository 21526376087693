import Layout from "../components/layout"
import React from "react"

const PageNotFound = () => {

    return (
        <Layout>
            <main style={{ minHeight: '80vh' }} className="container d-flex justify-content-center align-items-center">
                <h1 >
                    Tuto  stránku bohužel neznáme :/
                </h1>

            </main>
        </Layout>
    )
}

export default PageNotFound